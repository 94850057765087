import React from 'react';
import Modal from '@mui/material/Modal';
import { useSigilContext } from '../../context/sigilContext';
import { Box, Button, TextField, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { saveSigil } from '../../util/functions';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  bgcolor: '#2C2C2C',  // Cambia el color de fondo
  boxShadow: 24,
  p: 4,
  color: 'white',  // Cambia el color del texto
  borderRadius: '8px',
};

const SaveModal = () => {

  const { 
    openModal, 
    setOpenModal, 
    setMessage,
    criptoText,
    editIndex,
    setEditIndex,
    originalText,
    i18n,
    shapes,
    savedName,
    setSavedName
  } = useSigilContext();

  const translate = { ...i18n.saveModal, ...i18n.message };
  
  const save = () => {
    const result = saveSigil({
      name: savedName, 
      shapes, 
      criptoText,
      editIndex,
      originalText
    });
    if(!result.error){
      setOpenModal(false);
      setEditIndex(result.index);
      setMessage({
        severity: 'success',
        text: translate.save.success,
      })
    }else{
      setMessage({
        severity: 'error',
        text: translate.save.error
      })
    }
  }

  return ( 
    <Modal 
      open={openModal}
      onClose={() => setOpenModal(false)}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }
        }
      }}
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            marginBottom: '20px'
          }}>
            <Typography variant="h6" component="h2" sx={{ color: 'white' }}>
              {translate.title}
            </Typography>
            <CloseOutlinedIcon onClick={ () => setOpenModal(false)} />
          </Box>  
          <TextField
            onChange={(e) => setSavedName(e.target.value)}
            placeholder={translate.placeholder} 
            variant="outlined"
            value={savedName} 
            slotProps={{
              input: {
                sx: {
                  color: 'white'
                }
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // Color del borde
                },
                '&:hover fieldset': {
                  borderColor: '#FFFFFF', // Color del borde al pasar el mouse
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFFFFF', // Color del borde cuando está enfocado
                },
              },
            }}
          />
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px'
          }}> 
            <Button 
              variant="contained"
              sx={{ marginLeft: '20px' }}
              onClick={() => { save() }}
            > 
              {translate.saveButton} 
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SaveModal;
