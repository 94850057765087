import { Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useSigilContext } from "../context/sigilContext";

const InviteMeCoffee = () => {
  const [amount, setAmount] = useState('1')
  const initialOptions = {
    "client-id": "AQJuGwcGqNqj9_RUBMMbXAHRPnCyGLlQDHDn-hqVltVkbhpc1mSS88BEgkRFCEHTTq3oD1-CdgU3FLMM",
    // "enable-funding": "venmo",
    "disable-funding": "venmo",
    "buyer-country": "US",
    currency: "USD",
    "data-page-type": "product-details",
    components: "buttons",
    "data-sdk-integration-source": "developer-studio",
  };

  const { i18n, setMessage } = useSigilContext();
  const translate = i18n.coffee;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }} >
      <div>
        <PayPalScriptProvider options={initialOptions}>
          <div style={{ textAlign: "center", padding: "20px" }}>
            <h2>{translate.title}</h2>
            <p>{translate.description}</p>
            <div  style={{ marginBottom: "15px" }}>
                <PayPalButtons
                  style={{ 
                    layout: "vertical", 
                    color: "silver", 
                    shape: "rect", 
                    label: "donate",
                    branding: false 
                  }}
                  fundingSource="paypal"
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: '1',
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                      setMessage(`${translate.thanks} $${amount}, ${details.payer.name.given_name}!`);
                    });
                  }}
                />
                <Divider 
                  sx={{ background: '#444444'}} 
                />
            </div>
          </div>
        </PayPalScriptProvider>
      </div>
      <div>
        <FormControl sx={{ backgroundColor: "transparent" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            sx={{ gap: 3 }}
          >
            <FormControlLabel
              sx={{ border:'none' }}
              value={'1'}
              control={<Radio />}
              label='1$'
            />
            <FormControlLabel
              sx={{ border:'none' }}
              value='3'
              control={<Radio />}
              label='3$'
            />
            <FormControlLabel
              sx={{ border:'none' }}
              value='5'
              control={<Radio />}
              label='5$'
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default InviteMeCoffee;
