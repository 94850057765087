import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Logo } from '../../assets/logo';
import { useSigilContext } from '../../context/sigilContext';

const SigilToolBar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };
  const { i18n } = useSigilContext();

  const menuItems = i18n.menu;

  const handleClick = (index) => {
    const navigatePage = {
      '0': () => navigate('/'),
      '1': () => navigate('/my-sigils'),
      '2': () => navigate('/info'),
      '3': () => navigate('/about'),
      '4': () => navigate('/coffee'),
    }
    navigatePage[`${index}`]()
  }

  return (
    <div>
      {/* AppBar con el ícono de la aplicación a la izquierda y el menú de hamburguesa a la derecha */}
      <AppBar position="static">
        <Toolbar>
          {/* Ícono de la aplicación a la izquierda */}
          <IconButton edge="start" color="inherit" aria-label="app-icon" onClick={ ()=> handleClick(0)}>
            <Logo />
          </IconButton>

          {/* Título centrado */}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
           
          </Typography>

          {/* Menú de hamburguesa a la derecha */}
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer que contiene las opciones */}
      <Drawer 
        anchor="right" 
        open={open} 
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',    // Centrar horizontalmente
          }
        }}
      >
        <Box
          sx={{ 
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'column', 
            justifyContent: 'space-between',
            flexGrow: 1,
            alignItems: 'center',
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          
        > 
          <List>
            {menuItems.map((text, index) => (
              <ListItem button key={index} onClick={ ()=> handleClick(index) }>
                <ListItemText primary={text} 
                  sx={{
                    transition: 'text-shadow 0.3s',
                    '& span': {
                      textAlign: 'center',
                      fontSize: '24px',
                      marginBottom: '24px'
                    },
                    '&:hover span': {
                      textShadow: '0px 2px 4px #FFFFFF',
                    },
                    '&:active span': {
                      textShadow: '0px 2px 4px #FFFFFF',
                    },
                  }} 
                />
              </ListItem>
            ))}
          </List>
          <IconButton 
            sx={{
              backgroundColor: 'white', 
              width: '54px',
              height: '54px', 
              marginBottom: '20px' 
            }}
            onClick={ () => { toggleDrawer(false)  }}
          > 
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </Drawer>
    </div>
  );
};

export default SigilToolBar;
