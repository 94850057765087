import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { DefaultIcon } from '../assets/defaultIcon';
import { formatDate } from '../util/functions';
import DeleteModal from '../components/deleteModal/DeleteModal';
import { useSigilContext } from '../context/sigilContext';
import { useNavigate } from 'react-router-dom';


const MySigils = () => {
  const [sigilsStore, setSigilsStore ] = useState(undefined);
  const { setOpenDeleteModal, 
          setMessage, 
          setShapes, 
          setEditIndex,
          setCriptoText,
          setSavedName,
          i18n,
          savedName
  } = useSigilContext();
  console.log({
    savedName
  })
  const translate = {...i18n.mySigils, ...i18n.message };
  const [indexSelected, setIndexSelected] = useState();
  const navigate = useNavigate();

  useEffect(()=> {
    console.log(JSON.parse(localStorage.getItem('sigils')))  
    setSigilsStore(JSON.parse(localStorage.getItem('sigils')));
  }, [])

  const deleteSigil = (index) => {
    const sigilsTemp = [...sigilsStore]
    sigilsTemp.splice(index,1)
    setSigilsStore(sigilsTemp)
    localStorage.setItem('sigils', JSON.stringify(sigilsTemp))
    setMessage({
      severity: 'success',
      text: translate.delete.success
    });
    setOpenDeleteModal(false);
  }

  const editSigil = (index) => {
    setShapes(sigilsStore[index].shapes);
    setEditIndex(index);
    setCriptoText(sigilsStore[index].criptoText)
    navigate('/drawing')
    setSavedName(sigilsStore[index].name)
  }


  return ( 
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',

      }} >
      <p
        style={{
          fontSize: '24px',
          lineHeight: '32px'
        }}
      >
        {translate.title}
      </p>

     {(!sigilsStore || sigilsStore.length === 0) && (
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '24px',
            gap: '24px'
          }}
        >
          <img 
            src={require('../assets/emptylist.png')} 
            alt='Empty list'
            width={126}
            height={126}
            style={{ opacity: 0.5 }}
          />
          <span style={{ opacity: 0.5 }}>{translate.emptyList}</span>
        </Box>
     )}   

      <List dense={false}>
          {sigilsStore && sigilsStore.map( (sigil, index) =>
              <Box key={`list-${index}`}>
                <ListItem secondaryAction={
                  <Box sx={{ display: 'flex' }}>
                    <ListItemAvatar>
                      <Avatar>
                        <IconButton onClick={() => {
                          setIndexSelected(index);
                          setOpenDeleteModal(true)
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemAvatar>
                      <Avatar>
                      <IconButton onClick={() => editSigil(index)}>
                        <EditIcon />
                      </IconButton>
                      </Avatar>
                    </ListItemAvatar>
                  </Box>
                  }
                >
                  <Box sx={{ marginRight: '15px'}}>
                    <DefaultIcon />
                  </Box>
                  <ListItemText
                    secondaryTypographyProps={{
                      style: { color: '#8b8b8b' }
                    }}
                    primary={sigil.name}
                    secondary={ formatDate(sigil.createAt, 'es')}
                  />
                </ListItem>
                <Divider 
                  key={`divider-${index}`} 
                  sx={{ background: '#444444'}} 
                  variant="middle" component="li" 
                />
              </Box>
            )
          }
      </List>    
      <DeleteModal deleteSigil={ deleteSigil } index={indexSelected} />    
    </Box> 
  );
}
 
export default MySigils;