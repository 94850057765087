
import { styled } from '@mui/system';
import { Typography, Box } from '@mui/material';

const Paragraph = ({children}) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    paddingLeft: '25px',
    paddingRight: '25px',
    textAlign: 'center',
    textIndent: '25px',
    marginTop: '25px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '25%',
      paddingRight: '25%',
    },
  }));

  return ( 
    <StyledBox>
    <Typography
      variant="body1"
      sx={{ 
        textAlign: 'left',
        paddingX: '25px',
      }}
    >
      {children}
      </Typography>
    </StyledBox>
  );
}
 
export default Paragraph;