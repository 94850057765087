import { Box } from "@mui/material";
import Paragraph from "../components/paragraph/Paragraph";
import { useSigilContext } from "../context/sigilContext";

const About = () => {
  const { i18n } = useSigilContext();
  const translate = i18n.about;
 
  return ( 
    <Box>
      <h1>{translate.title}</h1>
      <Paragraph>
           {translate.description} 
           <a 
              href={`mailto:mysticcodelab@gmail.com?subject=${translate.suggestions}`}
              style={{ color: "#007bff", textDecoration: "none", marginLeft: "5px" }}
            >
              mysticcodelab@gmail.com
            </a>.
      </Paragraph>
      <Paragraph>
          {translate.userInfo}
      </Paragraph>
    </Box>
   );
}
 
export default About;