import React from "react";
import { Routes, Route } from 'react-router-dom'
import Home from "../pages/Home";
import DrawBox from "../pages/DrawBox";
import SigilToolbar from "../components/toolbar/SigilToolbar";
import InfoPage from "../pages/InfoPage";
import MySigils from "../pages/MySigils";
import "../App.css";
import CustomSnackbar from "../components/customSnackbar/CustomSnackbar";
import DrawerImage from "../components/drawerImage/DrawerImage";
import Policy from "./Policy";
import About from "./About";
import InviteMeCoffee from "./InviteMeCoffee";



function Main() {


return (
    <div className="App">
      < SigilToolbar/>
      <Routes>
        <Route path="/"  element={<Home />}/>
        <Route path="drawing"  element={<DrawBox />}/>
        <Route path="info"  element={<InfoPage />}/>
        <Route path="my-sigils"  element={<MySigils />}/>
        <Route path="my-sigils"  element={<MySigils />}/>
        <Route path="policy"  element={<Policy />}/>
        <Route path="about"  element={<About />}/>
        <Route path="coffee"  element={<InviteMeCoffee />}/>
      </Routes>
      <CustomSnackbar />
      <DrawerImage />
    </div>
  );
}

export default Main;
